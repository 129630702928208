<template>
  <div class="setting-item s-values">
    <h3 class="s-values__title">
      Значения и связанные группы
    </h3>
    <draggable v-model="localSetting.values" @sort="updateSortOrder">
      <div
        v-for="el in localSetting.values"
        :key="el.techId"
        class="s-values__item"
      >
        <div :class="[ 's-values__item-value', { 's-values__item-value--has-groups': hasGroups(el) } ]">
          <esmp-input
            :ref="`input_${el.techId}`"
            v-model="el.name"
            icon="link"
            @button-click="toggleGroupsList(el.techId)"
          />
          <div
            v-if="isGroupsOpen(el.techId)"
            class="s-values__connected-groups"
          >
            <esmp-checkbox-group
              :key="`${el.techId}_check`"
              v-model="el.connectedGroups"
              class="s-values__connected-groups__item"
            >
              <esmp-checkbox
                v-for="group in groupList"
                :key="group.techId"
                :label="group.techId"
              >
                {{ group.name }}
              </esmp-checkbox>
            </esmp-checkbox-group>
          </div>
        </div>
        <div
          class="s-values__item-actions"
        >
          <span
            v-if="localSetting.values.length > 1"
            @click="remove(el.techId)"
            class="s-values__item-actions__item"
          >
            <esmp-icon name="trash" />
          </span>
        </div>
      </div>
    </draggable>
    <div class="s-values__actions">
      <esmp-button
        class="s-values__actions-item"
        size="small"
        @click="add"
      >
        Добавить
      </esmp-button>
      <esmp-upload
        class="s-values__actions-item s-values__actions-loader"
        v-model="files"
        :show-upload-list="false"
        @change="importHandler"
      >
        <esmp-button
          size="small"
          view="outline"
          class="ml-10"
        >
          Импорт
        </esmp-button>
      </esmp-upload>
      <esmp-button
        class="s-values__actions-item ml-10"
        size="small"
        view="outline"
        @click="isShowedModal = true"
      >
        Экспорт
      </esmp-button>
    </div>
    <esmp-modal v-model="isShowedModal" footer-hide>
      <esmp-input label="Название файла" v-model="filename" />
      <esmp-button
        size="small"
        @click="exportHandler"
        class="mt-10"
      >
        Экспортировать
      </esmp-button>
    </esmp-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import getGroups from '@/components/service-form/helpers/getGroups';
import saveBlob from '@/components/service-form/helpers/saveBlob';

export default {
  name: 'SValues',
  inject: ['globalState'],
  model: {
    prop: 'setting',
    event: 'input',
  },
  components: { draggable },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openGroupsList: [],
      files: [],
      isShowedModal: false,
      filename: 'Значения',
    };
  },
  computed: {
    groupList() {
      return getGroups(this.globalState.elements);
    },
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    lastOrder() {
      return this.localSetting?.values?.length
        ? Math.max(...this.localSetting.values.map((el) => Number(el.sortOrder)))
        : 0;
    },
  },
  methods: {
    uid() {
      return Number(window.HM_CURRENT_UNIQID());
    },
    add() {
      const techId = this.uid();
      this.localSetting.values.push({
        techId,
        name: '',
        sortOrder: this.lastOrder + 1,
        connectedGroups: [],
      });
      this.$nextTick(() => {
        this.$refs[`input_${techId}`][0].focus();
      });
    },
    remove(id) {
      this.localSetting.values = this.localSetting.values.filter((el) => el.techId !== id);
      this.updateSortOrder();
    },
    updateSortOrder() {
      this.localSetting.values = this.localSetting.values.map((el, i) => ({
        ...el,
        sortOrder: i + 1,
      }));
    },
    toggleGroupsList(id) {
      if (!this.groupList?.length) return;

      if (this.openGroupsList.includes(id)) {
        this.openGroupsList = this.openGroupsList.filter((i) => i !== id);
      } else {
        this.openGroupsList.push(id);
      }
    },
    importHandler(val) {
      const formDto = new FormData();
      formDto.append('excelFile', val[0], val[0].name);
      this.$API.controls.importValues(formDto)
        .then(({ data }) => {
          const values = [];
          data.forEach((el, index) => {
            values.push({
              techId: this.uid(),
              name: el,
              sortOrder: index + 1,
              connectedGroups: [],
            });
          });
          this.localSetting.values = values;
        });
    },
    exportHandler() {
      this.$API.controls.exportValues(this.localSetting.values.map((item) => item.name))
        .then(({ data }) => {
          this.isShowedModal = false;
          saveBlob(this.filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data);
        });
    },
    isGroupsOpen(id) {
      return this.openGroupsList.includes(id);
    },
    hasGroups(element) {
      return !!element.connectedGroups?.length;
    },
    setOpenGroupsList(values) {
      values.forEach((value) => {
        if (value.connectedGroups) {
          this.openGroupsList.push(value.id);
        }
      });
    },
  },
  mounted() {
    this.setOpenGroupsList(this.localSetting.values);
  },
};
</script>
<style lang="scss" scoped>
.s-values {
  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    &-value {
      flex: 1;
      ::v-deep .esmp-input .esmp-input-icon {
        color: $color-grayscale-60;
      }
      &--has-groups {
        ::v-deep .esmp-input .esmp-input-icon {
          color: $color-primary-1-day;
        }
      }
    }
    &-actions {
      flex-shrink: 0;
      margin-top: 10px;
      margin-left: 10px;
      &__item {
        transition: color $base-animation;
        &:hover {
          cursor: pointer;
          color: $color-primary-1-day;
        }
      }
    }
  }

  &__connected-groups {
    margin-left: 10px;
    margin-bottom: 10px;
    &__item {
      margin-top: 8px;
      ::v-deep .esmp-checkbox-group-item {
        margin-top: 4px;
      }
    }
  }

  &__actions {
    margin-top: 20px;
    &-loader {
      display: inline-block;
    }
    &-item + &-item {
      margin-top: 10px;
    }
  }
}

</style>
